import React from "react";
import { Link } from "gatsby";

import { MdArrowRight } from "react-icons/md";

export default function Breadcrumb({ links }) {
  return (
    <div className="my-4 text-muted  text-center">
      {links.map((link, idx) => (
        <>
          <Link to={link.link} className="small text-uppercase text-dark me-3">
            {link.text}
          </Link>
          {idx < links.length - 1 && (
            <span className=" me-3">
              <MdArrowRight />
            </span>
          )}
        </>
      ))}
    </div>
  );
}
