import React from "react";
import Layout from "../components/layout";
import Title from "../components/title";
import Breadcrumbs from "../components/breadcrumb";

import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Zoom from "react-medium-image-zoom";

import { Container, Row, Col } from "react-bootstrap";
import * as styles from "../styles/templates/experience.module.scss";

export const query = graphql`
  query ($slug: String!) {
    contentfulLocations(slug: { eq: $slug }) {
      slug
      title
      images {
        gatsbyImageData(quality: 100, width: 300, layout: FIXED)
      }
      description {
        raw
      }
    }
  }
`;

const Location = ({ data }) => {
  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  );
  const Text = ({ children }) => <p className={styles.text}>{children}</p>;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImageData, description } = node.data.target;

        return (
          <div>
            <GatsbyImage image={gatsbyImageData} alt={description} />
          </div>
        );
      },
    },
  };

  const location = data.contentfulLocations;
  return (
    <>
      <Layout>
        <Title picture title={location.title} />

        <Container className={` ${styles.content}`}>
          <Breadcrumbs
            links={[
              {
                text: "Locations",
                link: "/locations",
              },
              {
                text: location.title,
                link: `/locations/${location.slug}`,
              },
            ]}
          />

          <Col
            lg={12}
            className="mx-auto d-flex align-items-center text-center "
          >
            {/* Photo Gallery */}
            {location.images && (
              <Container className="">
                <Row>
                  <Col xs={12} xl={12} xxl={12} className=" mx-auto px-0">
                    <Row
                      xs={2}
                      sm={3}
                      lg={4}
                      className="g-2 mb-4 justify-content-center"
                    >
                      {location.images.map((image) => {
                        return (
                          <Col>
                            <Zoom>
                              <GatsbyImage
                                image={image.gatsbyImageData}
                                alt={image.title}
                                style={{ height: "300px" }}
                              />
                            </Zoom>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </Container>
            )}
          </Col>

          {/* Add SEO Here */}

          <Col lg={8} className="mx-auto text-start ">
            <div className={`${styles.content} mt-3 mb-5 pb-5`}>
              {renderRichText(location.description, options)}
            </div>
          </Col>
        </Container>
      </Layout>
    </>
  );
};

export default Location;
